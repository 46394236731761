import React from 'react';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import Products from './components/Products';
import Services from './components/Services';
import ContactForm from './components/ContactForm';
import Footer from './components/Footer';
import FadeInSection from './components/FadeInSection';
import './App.css';  // Import the CSS file for styling

export default function App() {
  return (
    <div className="app-container">
      <Header />
      <FadeInSection />
      <HeroSection />
      <Services />
      <Products />
      <ContactForm />
      <Footer />
    </div>
  );
}
