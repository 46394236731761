// ProductsSection.js
import React from 'react';
import FadeInSection from './FadeInSection';

const productsData = [
  {
    title: "AI Assistant",
    description: "Intelligent virtual assistant for enhanced customer support and engagement.",
    image: "/image (11).jpg?height=400&width=600",
  },
  {
    title: "Analytics Platform",
    description: "Comprehensive data visualization and analytics solution for businesses.",
    image: "/image (15).jpg?height=400&width=600",
  },
  {
    title: "E-commerce Suite",
    description: "Complete e-commerce solution with advanced features and integrations.",
    image: "/image (14).jpg?height=400&width=600",
  },
];

export default function ProductsSection() {
  return (
    <section id="products" className="py-32 bg-zinc-800">
      <div className="container mx-auto px-4">
        {/* Section Header */}
        <FadeInSection>
          <div className="max-w-3xl mx-auto text-center mb-20">
            <h2 className="text-4xl md:text-5xl font-extrabold tracking-tight mb-6 text-white">Our Products</h2>
            <p className="text-lg text-zinc-400">
              Discover our suite of innovative digital solutions
            </p>
          </div>
        </FadeInSection>

        {/* Product Cards */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {productsData.map((product, index) => (
            <FadeInSection key={index}>
              <div className="group bg-zinc-900 rounded-2xl overflow-hidden shadow-lg hover:shadow-xl transition-all duration-300">
                <div className="aspect-video relative overflow-hidden text-white">
                  <img
                    src={product.image}
                    alt={product.title}
                    className="w-full h-full object-cover transform transition-transform duration-300 group-hover:scale-105"
                  />
                </div>
                <div className="p-8">
                  <h3 className="text-xl font-bold mb-3 text-white">{product.title}</h3>
                  <p className="text-zinc-400 mb-6">{product.description}</p>
                  <a
                    href="#"
                    className="inline-flex items-center text-cyan-400 hover:text-cyan-300 font-medium transition-colors"
                  >
                    View Details <span className="ml-2">→</span>
                  </a>
                </div>
              </div>
            </FadeInSection>
          ))}
        </div>
      </div>
    </section>
  );
}
