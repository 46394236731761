// Footer.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';

export default function Footer() {
  const footerColumns = [
    {
      title: "Services",
      links: [
        { name: "AI Development", url: "https://yourwebsite.com/ai-development" },
        { name: "Digital Products", url: "https://yourwebsite.com/digital-products" },
        { name: "Startup Solutions", url: "https://yourwebsite.com/startup-solutions" },
        { name: "Consulting", url: "https://yourwebsite.com/consulting" },
      ],
    },
    {
      title: "Company",
      links: [
        { name: "About Us", url: "https://yourwebsite.com/about-us" },
        { name: "Careers", url: "https://yourwebsite.com/careers" },
        { name: "Blog", url: "https://yourwebsite.com/blog" },
        { name: "Contact", url: "https://yourwebsite.com/contact" },
      ],
    },
    {
      title: "Connect",
      links: [
        { name: "Twitter", url: "https://twitter.com/your-twitter" },
        { name: "LinkedIn", url: "https://www.linkedin.com/in/yogesh-singh-479a38262/" },
        { name: "GitHub", url: "https://github.com/Webys-org" },
        { name: "Discord", url: "https://discord.com/your-discord" },
      ],
    },
  ];

  return (
    <footer className="bg-zinc-900 text-zinc-100 py-20">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-12 mb-12">
          {/* Footer Logo and Description */}
          <div>
            <div className="flex items-center">
              <img src="/logov3.png" alt="Logo" className="h-10 w-auto" />
              <span className="text-lg font-semibold text-white">ebys</span>
            </div>
            <p className="mt-4 text-zinc-400">
              Empowering businesses with AI-driven web solutions and digital innovation.
            </p>
            {/* Social Icons */}
            <div className="mt-4 flex space-x-4">
              <a
                href="https://www.linkedin.com/in/yogesh-singh-479a38262/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-zinc-400 hover:text-cyan-400 transition-colors"
              >
                <FontAwesomeIcon icon={faLinkedin} size="lg" />
              </a>
              <a
                href="https://github.com/Webys-org"
                target="_blank"
                rel="noopener noreferrer"
                className="text-zinc-400 hover:text-cyan-400 transition-colors"
              >
                <FontAwesomeIcon icon={faGithub} size="lg" />
              </a>
            </div>
          </div>

          {/* Footer Links Columns */}
          {footerColumns.map((column, index) => (
            <div key={index}>
              <h3 className="text-lg font-semibold mb-4">{column.title}</h3>
              <ul className="space-y-2">
                {column.links.map((link, linkIndex) => (
                  <li key={linkIndex}>
                    <a
                      href={link.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-zinc-400 hover:text-cyan-400 transition-colors"
                    >
                      {link.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        <div className="border-t border-zinc-800 pt-8 text-center text-zinc-400">
          <p>&copy; {new Date().getFullYear()} webys.org. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}
