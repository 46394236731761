// FadeInSection.js
import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

export default function FadeInSection({ children }) {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: false, // Activate on each scroll into view
    threshold: 0.5,     // Trigger when 50% of the section is visible
  });

  useEffect(() => {
    if (inView) {
      controls.start("current");  // Apply "current" animation for active section
    } else {
      controls.start("hidden");   // Reset to hidden when out of view
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      transition={{ duration: 0.6, ease: "easeInOut" }}
      variants={{
        current: { opacity: 1, y: 0, scale: 1.05 },  // Slight scale-up for active section
        hidden: { opacity: 0, y: 30, scale: 1 },
      }}
    >
      {children}
    </motion.div>
  );
}
