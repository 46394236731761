import { useState } from 'react';
import FadeInSection from './FadeInSection';

const ContactForm = () => {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [status, setStatus] = useState('');

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { name, email, message } = formData;
    const mailtoLink = `mailto:askme50962@gmail.com?subject=New message from ${encodeURIComponent(
      name
    )}&body=${encodeURIComponent(`Name: ${name}\nEmail: ${email}\nMessage: ${message}`)}`;

    window.location.href = mailtoLink;

    setStatus('Redirecting to email client...');
    setFormData({ name: '', email: '', message: '' });
  };

  return (
    <section id="contact" className="py-32 bg-zinc-900">
      <div className="container mx-auto px-4">
        <FadeInSection>
          <div className="max-w-3xl mx-auto">
            <div className="text-center mb-16">
              <h2 className="text-4xl md:text-5xl font-extrabold tracking-tight mb-6 text-white">
                Start Your Project
              </h2>
              <p className="text-lg text-zinc-400">
                Let's create something extraordinary together
              </p>
            </div>
          </div>
        </FadeInSection>

        <FadeInSection>
          <div className="max-w-3xl mx-auto">
            <div className="bg-zinc-800/50 rounded-2xl shadow-xl p-8 md:p-12">
              <form onSubmit={handleSubmit} className="space-y-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <FadeInSection>
                    <div>
                      <label htmlFor="name" className="block text-sm font-medium text-zinc-300 mb-2">
                        Name
                      </label>
                      <input
                        type="text"
                        id="name"
                        value={formData.name}
                        onChange={handleChange}
                        className="w-full px-4 py-3 rounded-lg bg-zinc-900 border border-zinc-700 focus:ring-2 focus:ring-cyan-500 focus:border-transparent transition-colors text-white"
                        required
                      />
                    </div>
                  </FadeInSection>
                  
                  <FadeInSection>
                    <div>
                      <label htmlFor="email" className="block text-sm font-medium text-zinc-300 mb-2">
                        Email
                      </label>
                      <input
                        type="email"
                        id="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="w-full px-4 py-3 text-white rounded-lg bg-zinc-900 border border-zinc-700 focus:ring-2 focus:ring-cyan-500 focus:border-transparent transition-colors"
                        required
                      />
                    </div>
                  </FadeInSection>
                </div>

                <FadeInSection>
                  <div>
                    <label htmlFor="message" className="block text-sm font-medium text-zinc-300 mb-2">
                      Message
                    </label>
                    <textarea
                      id="message"
                      rows={4}
                      value={formData.message}
                      onChange={handleChange}
                      className="w-full px-4 py-3 rounded-lg bg-zinc-900 text-white border border-zinc-700 focus:ring-2 focus:ring-cyan-500 focus:border-transparent transition-colors"
                      required
                    ></textarea>
                  </div>
                </FadeInSection>

                <FadeInSection>
                  <button
                    type="submit"
                    className="w-full inline-flex items-center justify-center px-8 py-4 rounded-lg bg-gradient-to-r from-cyan-500 to-blue-600 text-white font-semibold hover:opacity-90 transition-all duration-300 transform hover:scale-[1.02]"
                  >
                    Send Message
                  </button>
                </FadeInSection>
              </form>
              {status && <p className="mt-4 text-center text-cyan-400">{status}</p>}
            </div>
          </div>
        </FadeInSection>
      </div>
    </section>
  );
};

export default ContactForm;
