// ServicesSection.js
import React from 'react';
import FadeInSection from './FadeInSection';

const servicesData = [
  {
    icon: "🌐",
    title: "AI Development",
    description: "Cutting-edge AI solutions that power modern web applications and digital experiences.",
  },
  {
    icon: "💡",
    title: "Digital Products",
    description: "Innovative digital products that solve real-world problems and drive business growth.",
  },
  {
    icon: "🚀",
    title: "Startup Solutions",
    description: "Tailored solutions to help startups scale and succeed in the digital landscape.",
  },
];

export default function ServicesSection() {
  return (
    <section id="services" className="py-32 bg-zinc-900">
      <div className="container mx-auto px-4">
        <FadeInSection>
          <div className="max-w-3xl mx-auto text-center mb-20">
            <h2 className="text-4xl md:text-5xl font-extrabold tracking-tight mb-6 text-white">Our Services</h2>
            <p className="text-lg text-zinc-400">
              Transforming ideas into exceptional digital solutions
            </p>
          </div>
        </FadeInSection>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {servicesData.map((service, index) => (
            <FadeInSection key={index}>
              <div className="group relative p-8 rounded-2xl bg-zinc-800/50 shadow-lg hover:shadow-xl transition-all duration-300 hover:-translate-y-1">
                <div className="absolute inset-0 rounded-2xl bg-gradient-to-br from-cyan-500/5 to-blue-600/5 opacity-0 group-hover:opacity-100 transition-opacity"></div>
                <div className="relative">
                  <div className="w-16 h-16 mb-6 rounded-2xl bg-gradient-to-br from-cyan-500 to-blue-600 flex items-center justify-center text-white text-2xl">
                    {service.icon}
                  </div>
                  <h3 className="text-xl font-bold mb-4 text-white">{service.title}</h3>
                  <p className="text-zinc-400 mb-6">{service.description}</p>
                  <a
                    href="#contact"
                    className="inline-flex items-center text-cyan-400 group-hover:text-cyan-300 font-medium transition-colors"
                  >
                    Learn More <span className="ml-2 transition-transform group-hover:translate-x-1">→</span>
                  </a>
                </div>
              </div>
            </FadeInSection>
          ))}
        </div>
      </div>
    </section>
  );
}
