// Header.js
import React, { useState, useEffect } from 'react';

export default function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => setScrolled(window.scrollY > 20);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    document.body.style.overflow = mobileMenuOpen ? 'hidden' : 'unset';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [mobileMenuOpen]);

  return (
    <>
      {/* Full-screen glass overlay for mobile menu */}
      <div
        className={`fixed inset-0 bg-black/20 backdrop-blur-xl transition-all duration-500 z-40 ${
          mobileMenuOpen ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'
        }`}
        onClick={() => setMobileMenuOpen(false)}
      />

      <header className={`fixed w-full z-50 transition-all duration-300 ${scrolled || mobileMenuOpen ? 'bg-zinc-900/80 backdrop-blur-lg shadow-sm' : 'bg-transparent'}`}>
        <div className="container mx-auto px-4 py-4">
          <div className="flex justify-between items-center">
            {/* Logo */}
            <div className="flex items-center">
              <img src="/logov3.png" alt="Logo" className="h-10 w-auto" />
              <span className="text-lg font-semibold text-white">ebys</span>
            </div>

            {/* Desktop Navigation */}
            <nav className="hidden md:flex space-x-8">
              {['Home', 'Services', 'Products', 'About'].map((item) => (
                <a key={item} href={`#${item.toLowerCase()}`} className="text-zinc-300 hover:text-cyan-400 font-medium tracking-wide transition-colors relative group">
                  {item}
                  <span className="absolute -bottom-1 left-0 w-0 h-0.5 bg-cyan-400 transition-all duration-300 group-hover:w-full"></span>
                </a>
              ))}
            </nav>

            {/* Get Started button and Mobile Menu Toggle */}
            <div className="flex items-center gap-6">
              <a href="#contact" className="hidden md:inline-flex items-center px-6 py-2.5 rounded-full font-semibold text-black bg-white hover:opacity-90 transition-opacity">
                Get Started
              </a>
              <button
                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                className="md:hidden p-2 text-zinc-300 rounded-lg transition-colors relative z-50"
                aria-label="Toggle menu"
              >
                <div className="relative w-6 h-6">
                  <span className={`absolute left-0 block w-6 h-0.5 bg-current transform transition-all duration-300 ${mobileMenuOpen ? 'rotate-45 top-3' : 'top-2'}`} />
                  <span className={`absolute left-0 block w-6 h-0.5 bg-current transform transition-all duration-300 ${mobileMenuOpen ? '-rotate-45 top-3' : 'top-4'}`} />
                </div>
              </button>
            </div>
          </div>
        </div>

        {/* Mobile Menu */}
        <div
          className={`fixed inset-x-0 top-0 h-screen transition-all duration-500 ease-in-out ${
            mobileMenuOpen ? 'translate-y-0 opacity-100' : '-translate-y-full opacity-0'
          } bg-zinc-900/95 backdrop-blur-xl pt-28 z-40`}
        >
          <nav className="container mx-auto px-4 py-6 flex flex-col items-center space-y-8">
            {['Home', 'Services', 'Products', 'About', 'Contact'].map((item) => (
              <a
                key={item}
                href={`#${item.toLowerCase()}`}
                className="relative group py-2 text-zinc-300 hover:text-cyan-400 font-medium tracking-wide transition-colors w-fit text-xl"
                onClick={() => setMobileMenuOpen(false)}
              >
                {item}
                <span className="absolute -bottom-1 left-1/2 w-0 h-0.5 bg-cyan-400 transition-all duration-300 group-hover:w-full group-hover:left-0"></span>
              </a>
            ))}
            <a href="#contact" className="mt-8 inline-flex items-center px-8 py-3 rounded-full font-semibold text-black bg-white hover:opacity-90 transition-opacity text-lg" onClick={() => setMobileMenuOpen(false)}>
              Get Started
            </a>
          </nav>
        </div>
      </header>
    </>
  );
}
